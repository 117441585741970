const TRAINER_CATEGORY_DETAILS = "TRAINER_CATEGORY_DETAILS";

export const trainerCategory = (state, action) => {
   switch (action.type) {
      case TRAINER_CATEGORY_DETAILS: {
         state = { ...state, ...action.payload };

         return state;
      }

      default:
         return state;
   }
};
