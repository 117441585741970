const GET_TRAINER_LISTINGS_HOME_DATA = "GET_TRAINER_LISTINGS_HOME_DATA";

export const trainerListingsHomeData = (state, action) => {
   switch (action.type) {
      case GET_TRAINER_LISTINGS_HOME_DATA: {
         state = { ...state, ...action.payload };

         return state;
      }
      default:
         return state;
   }
};