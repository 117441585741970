const GET_TRAINER_LISTINGS_DETAILS = "GET_TRAINER_LISTINGS_DETAILS";

export const trainerListingsDetails = (state, action) => {
   switch (action.type) {
      case GET_TRAINER_LISTINGS_DETAILS: {
         state = { ...state, ...action.payload };

         return state;
      }
      default:
         return state;
   }
};