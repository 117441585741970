const SET_LOAD = "SET_LOAD";

const initialData={
    loading:true
}
export const setLoad = (state, action) => {
   switch (action.type) {
      case SET_LOAD: {
         state = { ...initialData,...state, ...action.payload };
         return state;
      }
      default:
         return {...initialData,...state};
   }
};
